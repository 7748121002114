import Route_LandingPage from "@frontend/pages/LandingPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { ApiProvider } from "@frontend/components/ApiProvider";
import { AcquisitionGuard } from "./AcquisitionGuard";

function AppRouter() {
  return (
    <BrowserRouter>
      <ApiProvider>
        <Routes>
          <Route element={<AcquisitionGuard />}>{Route_LandingPage}</Route>
        </Routes>
      </ApiProvider>
    </BrowserRouter>
  );
}

export default AppRouter;
