// import { useCheckAcquisition } from "@flash-tecnologia/expense-web-activation-utils";
import { Outlet } from "react-router-dom";

export const AcquisitionGuard = () => {
  return <Outlet />;
  // const { data } = useCheckAcquisition();

  // if (data?.isPlgAnswered) {
  //   return <Outlet />;
  // }

  // const shouldRedirect = data?.isSelfAcquisition && !data?.isPlgAnswered;

  // return shouldRedirect ? (
  //   <Navigate to="/expense-lifecycle/acquisition/form" replace={false} />
  // ) : (
  //   <Outlet />
  // );
};
